import request from '@/utils/request'


// 查询创业者认证列表
export function listAttestE(query) {
  return request({
    url: '/biz/user-attest-e/list',
    method: 'get',
    params: query
  })
}

// 查询创业者认证分页
export function pageAttestE(query) {
  return request({
    url: '/biz/user-attest-e/page',
    method: 'get',
    params: query
  })
}

// 查询创业者认证详细
export function getAttestE(data) {
  return request({
    url: '/biz/user-attest-e/detail',
    method: 'get',
    params: data
  })
}

// 新增创业者认证
export function addAttestE(data) {
  return request({
    url: '/biz/user-attest-e/add',
    method: 'post',
    data: data
  })
}

// 修改创业者认证
export function updateAttestE(data) {
  return request({
    url: '/biz/user-attest-e/edit',
    method: 'post',
    data: data
  })
}

// 删除创业者认证
export function delAttestE(data) {
  return request({
    url: '/biz/user-attest-e/delete',
    method: 'post',
    data: data
  })
}
