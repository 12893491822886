<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" v-if="formType === 1">-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="用户姓名" prop="userName" >
        <a-input v-model="form.userName" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="用户手机号" prop="userMobile" >
        <a-input v-model="form.userMobile" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" readOnly />
      </a-form-model-item>
<!--      <a-form-model-item label="身份证国徽面" prop="idCardFront" >-->
<!--        <file-upload v-model="form.idCardFront" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="身份证人像面" prop="idCardBack" >-->
<!--        <file-upload v-model="form.idCardBack" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="投资机构" prop="companyName" >
        <a-input v-model="form.companyName" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="专长领域" prop="investment" >
        <a-input v-model="form.investment" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="职位" prop="position" >
        <a-input v-model="form.position" readOnly />
      </a-form-model-item>
<!--      <a-form-model-item v-show="formType == 3" label="投资经验" prop="investmentExperience" >-->
<!--        <a-select placeholder="请选择投资经验" v-model="form.investmentExperience">-->
<!--          <a-select-option v-for="(d, index) in investmentExperienceOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="投资阶段" prop="investmentStage" v-if="formType === 1">
        <a-input v-model="form.investmentStage" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 3" label="投资金额" prop="investmentAmount" >
        <a-input v-model="form.investmentAmount" readOnly />
      </a-form-model-item>
      <a-form-model-item v-show="formType == 2" label="审核结果" prop="auditResults" >
        <a-select placeholder="请选择审核结果" v-model="form.auditResults">
          <a-select-option :key="0" :value="0">请选择</a-select-option>
          <a-select-option :key="1" :value="1">通过</a-select-option>
          <a-select-option :key="2" :value="2">拒绝</a-select-option>
<!--          <a-select-option v-for="(d, index) in auditResultsOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-show="form.auditResults == 2" label="拒绝原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入拒绝原因" />
      </a-form-model-item>
<!--      <a-form-model-item v-show="formType == 3" label="备注" prop="remark" v-if="formType === 1">-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item v-show="formType == 3" label="投资阶段" prop="investmentStageValue" >
        <a-input v-model="form.investmentStageValue" readOnly />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button v-show="formType == 2" type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAttestVc, addAttestVc, updateAttestVc } from '@/api/biz/attestVc'
import {getAttestE} from "@/api/biz/attestE";

export default {
  name: 'CreateForm',
  props: {
    investmentExperienceOptions: {
      type: Array,
      required: true
    },
    auditResultsOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        userId: null,

        userName: null,

        userMobile: null,

        idCard: null,

        idCardFront: null,

        idCardBack: null,

        companyName: null,

        investment: null,

        position: null,

        investmentExperience: null,

        investmentStage: null,

        investmentAmount: null,

        auditResults: null,

        reason: null,

        createTime: null,

        remark: null,

        investmentStageValue: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        userId: null,
        userName: null,
        userMobile: null,
        idCard: null,
        idCardFront: null,
        idCardBack: null,
        companyName: null,
        investment: null,
        position: null,
        investmentExperience: null,
        investmentStage: null,
        investmentAmount: null,
        auditResults: null,
        reason: null,
        createTime: null,
        remark: null,
        investmentStageValue: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAttestVc({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    handleDetail (row, ids) {
      this.reset()
      this.formType = 3
      const id = row ? row.id : ids
      getAttestVc({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.auditResults === 0) {
            this.$message.error("请选择审核结果")
            return false;
          } else if (this.form.auditResults === 2 && !this.form.reason) {
            this.$message.error("请输入拒绝原因")
            return false;
          }

          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAttestVc(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAttestVc(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
