import request from '@/utils/request'


// 查询投资人认证列表
export function listAttestVc(query) {
  return request({
    url: '/biz/user-attest-vc/list',
    method: 'get',
    params: query
  })
}

// 查询投资人认证分页
export function pageAttestVc(query) {
  return request({
    url: '/biz/user-attest-vc/page',
    method: 'get',
    params: query
  })
}

// 查询投资人认证详细
export function getAttestVc(data) {
  return request({
    url: '/biz/user-attest-vc/detail',
    method: 'get',
    params: data
  })
}

// 新增投资人认证
export function addAttestVc(data) {
  return request({
    url: '/biz/user-attest-vc/add',
    method: 'post',
    data: data
  })
}

// 修改投资人认证
export function updateAttestVc(data) {
  return request({
    url: '/biz/user-attest-vc/edit',
    method: 'post',
    data: data
  })
}

// 删除投资人认证
export function delAttestVc(data) {
  return request({
    url: '/biz/user-attest-vc/delete',
    method: 'post',
    data: data
  })
}
